@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
* {
  margin: 0;
  padding: 0;
}

:root {
  --menuTextColor: #ff914d;
  --disabledOptionColor: #888;
  --tweetTextColor: #4db8ff;
  --settingsTextColor: #888;
  --yourfighters: #fff;
  --fullsizeText: 4rem;
  --mobileSizeText: 2rem;
}

body {
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
}

@font-face {
  font-family: Lovelo;
  src: url("Lovelo.efde7348.woff");
}

@font-face {
  font-family: CooperHewittHeavy;
  src: url("CooperHewitt-Heavy.c0ed07bc.woff");
}

@font-face {
  font-family: CaptureIt;
  src: url("Capture-it.f3638ec4.ttf");
}

@font-face {
  font-family: Exo;
  src: url("Exo.f416e616.ttf");
}

.flex-col {
  flex-direction: column;
  display: flex;
}

.main-menu {
  float: right;
  width: 60%;
  height: 70%;
  justify-content: space-evenly;
  margin-top: 100px;
  padding: 50px;
}

.main-menu-header {
  letter-spacing: 3px;
  color: var(--menuTextColor);
  background: none;
  border: none;
  font-family: CooperHewittHeavy;
  font-size: 48px;
}

.main-menu-option {
  letter-spacing: 3px;
  -webkit-text-stroke: 3px var(--menuTextColor);
  -webkit-text-fill-color: #0000;
  filter: drop-shadow(16px 16px 10px #000);
  text-align: right;
  background: none;
  border: none;
  font-family: CooperHewittHeavy;
  font-size: 60px;
}

.main-header {
  letter-spacing: 3px;
  filter: drop-shadow(16px 16px 10px #000);
  text-align: right;
  background: none;
  border: none;
  padding-bottom: 50px;
  font-family: CaptureIt;
  font-size: 90px;
}

.main-menu-option:disabled {
  -webkit-text-stroke: 2px var(--disabledOptionColor);
}

.main-menu-option:hover {
  cursor: pointer;
  -webkit-text-stroke: 0px var(--menuTextColor);
  -webkit-text-fill-color: var(--menuTextColor);
  font-size: 61px;
}

.main-menu-option:disabled:hover {
  cursor: default;
  -webkit-text-stroke: 2px var(--disabledOptionColor);
  -webkit-text-fill-color: #0000;
}

.tweet {
  letter-spacing: 3px;
  filter: drop-shadow(16px 16px 10px #000);
  text-align: right;
  color: var(--tweetTextColor);
  background: none;
  border: none;
  margin-top: 80px;
  font-family: CooperHewittHeavy;
  font-size: 20px;
}

.tweet:hover {
  font-size: 22px;
}

a:link {
  text-decoration: none;
}

.settings {
  letter-spacing: 3px;
  filter: drop-shadow(16px 16px 10px #000);
  text-align: right;
  color: var(--settingsTextColor);
  background: none;
  border: none;
  font-family: CooperHewittHeavy;
  font-size: 20px;
}

.settings:hover {
  font-size: 22px;
}

.beta {
  letter-spacing: 3px;
  filter: drop-shadow(16px 16px 10px #000);
  text-align: right;
  color: var(--settingsTextColor);
  background: none;
  border: none;
  margin-top: 20px;
  font-family: Exo;
  font-size: 16px;
}

.your-fighters {
  letter-spacing: 3px;
  -webkit-text-stroke: 2px var(--yourfighters);
  -webkit-text-fill-color: #0000;
  filter: drop-shadow(16px 16px 10px #000);
  text-align: right;
  background: none;
  border: none;
  margin-top: 50px;
  font-family: CooperHewittHeavy;
  font-size: 40px;
}

.your-fighters:hover {
  cursor: pointer;
  font-size: 42px;
}

.game-screen {
  height: 100vh;
}

.centered {
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbar {
  height: 35px;
  position: absolute;
}

.bg-video {
  z-index: -1;
  height: 100vh;
  width: 100%;
  position: fixed;
}

.wallet-adapter-button {
  color: var(--menuTextColor);
  cursor: pointer;
  height: 48px;
  background-color: #0000;
  border: 2px solid #0000;
  border-radius: 15px;
  align-items: center;
  margin-left: 5px;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #00808000;
  border: 2px solid #ff88005d;
  border-radius: 15px;
  transition: all .8s;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  border-style: solid;
  border-color: var(--menuTextColor);
  background-color: #009c9c00;
  border-width: 2px;
  border-radius: 15px;
  transition: all .8s;
  box-shadow: 0 0 50px orange;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: var(--menuTextColor);
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  min-height: calc(100vh - 6rem);
  justify-content: center;
  align-items: center;
  margin: 3rem;
  display: flex;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  max-width: 400px;
  background: #000c;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  width: 100%;
  color: #fff;
  background-color: #02575f;
  border: none;
  border-radius: 8px;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  transition: all .1s;
  display: block;
}

.bbbg {
  background-image: url("BACKYARD BG.7984f429.png");
}

.dfbg {
  background-image: url("DROID BG.27774cf3.png");
}

.tcbg {
  background-image: url("TIGER BG.f8d299b0.png");
}

.covered-bg {
  z-index: -1;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.fightergif {
  width: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -22%);
}

.roundvideo {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

/*# sourceMappingURL=index.2bd6d057.css.map */
