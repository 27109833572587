.bbbg {
  background-image: url("../assets/quickfight/BG/BACKYARD\ BG.png");
}

.dfbg {
  background-image: url("../assets/quickfight/BG/DROID\ BG.png");
}

.tcbg {
  background-image: url("../assets/quickfight/BG/TIGER\ BG.png");
}

.covered-bg {
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.fightergif {
  position: absolute;
  width: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -22%);
}

.roundvideo {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
