* {
  margin: 0;
  padding: 0;
}

:root {
  --menuTextColor: #ff914d;
  --disabledOptionColor: #888;
  --tweetTextColor: #4db8ff;
  --settingsTextColor: #888;
  --yourfighters: #fff;
  --fullsizeText: 4rem;
  --mobileSizeText: 2rem;
}

body {
  background-color: black;
  /* background-image: url('../assets/videos/logoswing.gif'); */
  background-repeat: no-repeat;
  background-size: cover;
}

@font-face {
  font-family: Lovelo;
  src: url("../fonts/Lovelo.woff");
}

@font-face {
  font-family: CooperHewittHeavy;
  src: url("../fonts/CooperHewitt-Heavy.woff");
}

/* @font-face {
    font-family: CooperHewittHeavyItalic;
    src: url('../fonts/CooperHewitt-HeavyItalic.woff');
} */

@font-face {
  font-family: CaptureIt;
  src: url("../fonts/Capture-it.ttf");
}

@font-face {
  font-family: Exo;
  src: url("../fonts/Exo.ttf");
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.main-menu {
  margin-top: 100px;
  float: right;
  padding: 50px;
  width: 60%;
  height: 70%;
  justify-content: space-evenly;
}

.main-menu-header {
  border: none;
  background: none;
  font-size: 48px;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  color: var(--menuTextColor);
}

.main-menu-option {
  border: none;
  background: none;
  font-size: 60px;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  -webkit-text-stroke: 3px var(--menuTextColor);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
}

.main-header {
  border: none;
  background: none;
  font-size: 90px;
  font-family: CaptureIt;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  padding-bottom: 50px;
}

.main-menu-option:disabled {
  -webkit-text-stroke: 2px var(--disabledOptionColor);
}

.main-menu-option:hover {
  cursor: pointer;
  font-size: 61px;
  -webkit-text-stroke: 0px var(--menuTextColor);
  -webkit-text-fill-color: var(--menuTextColor);
}

.main-menu-option:disabled:hover {
  cursor: default;
  -webkit-text-stroke: 2px var(--disabledOptionColor);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.tweet {
  margin-top: 80px;
  border: none;
  background: none;
  font-size: 20px;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  color: var(--tweetTextColor);
}

.tweet:hover {
  font-size: 22px;
}

a:link {
  text-decoration: none;
}

.settings {
  border: none;
  background: none;
  font-size: 20px;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  color: var(--settingsTextColor);
}

.settings:hover {
  font-size: 22px;
}

.beta {
  border: none;
  background: none;
  font-size: 16px;
  font-family: Exo;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
  color: var(--settingsTextColor);
  margin-top: 20px;
}

.your-fighters {
  margin-top: 50px;
  border: none;
  background: none;
  font-size: 40px;
  font-family: CooperHewittHeavy;
  letter-spacing: 3px;
  /* color: var(--menuTextColor); */
  -webkit-text-stroke: 2px var(--yourfighters);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  filter: drop-shadow(16px 16px 10px black);
  text-align: right;
}

.your-fighters:hover {
  cursor: pointer;
  font-size: 42px;
}

.game-screen {
  height: 100vh;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  height: 35px;
  position: absolute;
}

.bg-video {
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100%;
}
